import React from "react";

const Loader = () => {
    return (
        <>
            <div className="loader-main">
                <div class="loader"></div>
            </div>

        </>
    )
}

export default Loader;